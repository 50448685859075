import { Container } from "@mui/material";
import { graphql } from "gatsby";
import React from "react";
import RenderMarkdown from "../cms/RenderMarkdown";
import IntroSection from "../components/public/IntroSection";
import PublicLayout from "../layout/PublicLayout";

type TermsOfServiceTemplateProps = {
  content: string;
  preview?: boolean;
};

export const TermsOfServiceTemplate: React.FC<TermsOfServiceTemplateProps> = ({
  content = "",
  preview
}) => {
  return <RenderMarkdown runtime={preview}>{content}</RenderMarkdown>;
};

type TermsOfServiceProps = {
  data: any;
};

const TermsOfService: React.FC<TermsOfServiceProps> = ({ data }) => {
  return (
    <PublicLayout seoTitle="Terms of Service">
      <IntroSection title="Terms of Service" />
      <Container maxWidth="md">
        <TermsOfServiceTemplate content={data.mdx.body} />
      </Container>
    </PublicLayout>
  );
};

export const termsOfServicePageQuery = graphql`
  query TermsOfServicePage($id: String!) {
    mdx(id: { eq: $id }) {
      body
    }
  }
`;

export default TermsOfService;
